import {appSyncQuery} from "./index";
import * as gql from "../constants/GraphQL";

const listString = `query ListInvoices($direction: MoveDirection = FORWARD, $limit: Int = 10, $offset: String = "", $offset_id: String = "", $query: SqlQuery = {}, $payor_query: [QueryPair] = {}) {
  invoices(direction: $direction, limit: $limit, offset: $offset, offset_id: $offset_id, query: $query) {
    items {
      merchant_uid
      created_date
      due_by
      fee_mode
      invoice_amount
      invoice_description
      invoice_date
      invoice_id
      invoice_name
      is_secure
      merchant_invoice_number
      payor(query_list: $payor_query) {
        email
        full_name
        phone
        payor_id
      }
      security_pin
      settings
      status
      total_paid_amount
    }
    total_row_count
  }
}
`

export const list = (order, offset, limit, filter, direction) => {
    let sort = [{key: 'created_date', direction: order}]
    const queryObject = {
        query_list: filter.invoiceQuery,
        sort_list: sort,
    }
    const variables = {
        query: queryObject,
        offset_id: offset?.invoiceId,
        offset: offset?.createdDate,
        limit: limit,
        direction: direction,
        payor_query: filter.payorQuery,
    }
    return appSyncQuery(listString, variables)
}

const getString = `query GetInvoice($query: SqlQuery = {}) {
  invoices(query: $query) {
    items {
      merchant_uid
      created_date
      due_by
      fee_mode
      invoice_amount
      invoice_description
      invoice_date
      invoice_id
      invoice_name
      is_secure
      merchant_invoice_number
      offline_transactions {
        amount
        instance_id
        invoice_id
        note
        transaction_date
        type
      }
      payor {
        email
        full_name
        phone
      }
      security_pin
      settings
      status
      total_paid_amount
    }
  }
}
`

export const get = (invoiceId) => {
    const query = {
        query_list: [
            {
                key: "invoice_id",
                value: `%${invoiceId}%`,
                operator: gql.LIKE
            }
        ]
    }
    const variables = {
        query: query
    }
    return appSyncQuery(getString, variables)
}

const createInvoiceString = `mutation CreateInvoice($invoice_id: String,
                                                $settings: AWSJSON,
                                                $currency: String = "USD", 
                                                $due_by: AWSDate, 
                                                $fee_mode: FeeMode!, 
                                                $invoice_amount: Int!, 
                                                $invoice_description: String, 
                                                $invoice_name: String!, 
                                                $is_secure: Boolean!, 
                                                $merchant_uid: String!, 
                                                $email: String!, 
                                                $full_name: String!,
                                                $merchant_invoice_number: String,
                                                $payor_id: String) {
  createInvoice(input: {currency: $currency, due_by: $due_by, fee_mode: $fee_mode, invoice_amount: $invoice_amount, invoice_description: $invoice_description, invoice_id: $invoice_id, invoice_name: $invoice_name, is_secure: $is_secure, merchant_uid: $merchant_uid, payor: {payor_id: $payor_id, email: $email, full_name: $full_name, merchant_uid: $merchant_uid}, settings: $settings, merchant_invoice_number: $merchant_invoice_number}) {
      merchant_uid
      created_date
      due_by
      fee_mode
      invoice_amount
      invoice_description
      invoice_date
      invoice_id
      invoice_name
      is_secure
      merchant_invoice_number
      payor {
        full_name
        phone
        payor_id
      }
      security_pin
      settings
      status
      total_paid_amount
  }
}`

export const createInvoice = variables => {
    return appSyncQuery(createInvoiceString, variables)
}

const deleteInvoiceString = `mutation DeleteInvoice($invoice_id: String!) {
  deleteInvoice(invoice_id: $invoice_id)
}`

export const deleteInvoice = async (invoiceId) => {
    const variables = {
        invoice_id: invoiceId
    }
    return appSyncQuery(deleteInvoiceString, variables)
}

const createOfflinePaymentString = `mutation CreateOfflineTransaction($amount: Int!, $invoice_id: String!, $note: String, $transaction_date: AWSDate!, $type: OfflineTransactionType!) {
  createOfflineTransaction(input: {amount: $amount, invoice_id: $invoice_id, note: $note, transaction_date: $transaction_date, type: $type}) {
    amount
  }
}
`

export const createOfflinePayment = (amount, invoiceId, note, transactionDate, type) => {
    const variables = {
        amount: amount,
        invoice_id: invoiceId,
        note: note,
        transaction_date: transactionDate,
        type: type,
    }
    return appSyncQuery(createOfflinePaymentString, variables)
}

const sendEmailString = `mutation SendInvoiceEmail($invoice_id: String!) {
   createInvoiceEmail(invoice_id: $invoice_id)
}
`

export const sendEmail = (invoiceId) => {
    const variables = {
        invoice_id: invoiceId
    }
    return appSyncQuery(sendEmailString, variables)
}