import {appSyncQuery} from "./index";
import * as gql from "../constants/GraphQL";

const listString = `query ListRecurringPayments($direction: MoveDirection, $limit: Int, $offset: String, $offset_id: String, $recurring_query: SqlQuery, $payment_method_query: [QueryPair], $payor_query: [QueryPair]) {
  recurringPayments(direction: $direction, limit: $limit, offset: $offset, offset_id: $offset_id, query: $recurring_query) {
    items {
      amount_per_payment
      account_code
      created_date
      recurring_description
      fee_mode
      fee_per_payment
      is_active
      prev_payment_date
      merchant_uid
      next_payment_date
      payment_interval
      payment_method(query_list: $payment_method_query) {
        card_brand
        exp_date
        last_four
        payment_type
        is_active
        payment_method_id
        payor {
          email
          full_name
          phone
        }
      }
      payor(query_list: $payor_query) {
        email
        full_name
        phone
        payor_id
       }
      recurring_name
      recurring_id
      reference
      remaining_payments
      status
      total_amount_per_payment
    }
    total_row_count
  }
}
`

export const list = (order, offset, limit, filter, direction) => {
    let sort = [{key: 'created_date', direction: order}]
    const queryObject = {
        query_list: filter.recurringQuery,
        sort_list: sort,
    }
    const variables = {
        recurring_query: queryObject,
        offset_id: offset?.recurringId,
        offset: offset?.createdDate,
        limit: limit,
        direction: direction,
        payor_query: filter.payorQuery,
        payment_method_query: filter.paymentMethodQuery,
    }
    return appSyncQuery(listString, variables)
}

const getString = `query GetRecurringPayment($recurring_query: SqlQuery) {
  recurringPayments(query: $recurring_query) {
    items {
      amount_per_payment
      created_date
      recurring_description
      fee_mode
      fee_per_payment
      is_active
      prev_payment_date
      merchant_uid
      next_payment_date
      payment_interval
      payment_method {
        card_brand
        exp_date
        last_four
        payment_type
        payment_method_id
        is_active
        payor {
          email
          full_name
          phone
        }
      }
      payor {
        email
        full_name
        phone
        payor_id
       }
      recurring_name
      recurring_id
      remaining_payments
      status
      total_amount_per_payment
    }
    total_row_count
  }
}
`

export const get = (id) => {
    const queryObject = {
        query_list: [
            {
                key: "recurring_id",
                value: id,
                operator: gql.EQUAL
            }
        ],
        sort_list: [],
    }
    const variables = {
        recurring_query: queryObject
    }
    return appSyncQuery(getString, variables)
}

export const updateString = `mutation UpdateRecurring($payment_method_id: String!, $recurring_id: String!) {
  updateRecurringPayment(input: {payment_method_id: $payment_method_id, recurring_id: $recurring_id}) {
      amount_per_payment
      account_code
      created_date
      recurring_description
      fee_mode
      fee_per_payment
      is_active
      prev_payment_date
      merchant_uid
      next_payment_date
      payment_interval
      payment_method {
        card_brand
        exp_date
        last_four
        payment_type
        is_active
        payment_method_id
        payor {
          email
          full_name
          phone
        }
      }
      payor {
        email
        full_name
        phone
        payor_id
       }
      recurring_name
      recurring_id
      reference
      remaining_payments
      status
      total_amount_per_payment
      }
}`

export const update = (paymentMethod, recurringId) => {
    const variables = {
        payment_method_id: paymentMethod,
        recurring_id: recurringId,
    }
    return appSyncQuery(updateString, variables)
}

const cancelString = `mutation MyMutation($recurring_id: String!) {
  cancelRecurringPayment(recurring_id: $recurring_id)
}`

export const cancel = (id) => {
    const variables = {
        recurring_id: id,
    }
    return appSyncQuery(cancelString, variables)
}

const payOffString = `mutation PayOffRecurring($account_code: String, $amount: Int!, $fee: Int, $fee_mode: FeeMode!, $merchant_uid: String!, $payment_method_id: String!, $receipt_description: String!, $recurring_id: String!, $reference: String) {
  createOneTimePayment(amount: $amount, merchant_uid: $merchant_uid, payment_method_id: $payment_method_id, account_code: $account_code, fee: $fee, fee_mode: $fee_mode, receipt_description: $receipt_description, recurring_id: $recurring_id, reference: $reference, send_receipt: true) {
    amount
    }
}
`

export const payOff = (data) => {
    const variables = {
        amount: data.amount,
        merchant_uid: data.merchant_uid,
        payment_method_id: data.payment_method_id,
        account_code: data.account_code,
        fee: data.fee,
        fee_mode: data.fee_mode,
        receipt_description: data.receipt_description,
        recurring_id: data.recurring_id,
        reference: data.reference,
    }
    return appSyncQuery(payOffString, variables)
}

const calcFeesString = `query CalcFees($amount: Int!, $merchant_uid: String!) {
  serviceFeeAmount(amount: $amount, merchant_uid: $merchant_uid) {
    ach {
      adjusted_total
      total
      fee
    }
    card {
      adjusted_total
      total
      fee
    }
  }
}
`

export const calcFees = (amount, merchant_uid) => {
    const variables = {
        amount: amount,
        merchant_uid: merchant_uid,
    }
    return appSyncQuery(calcFeesString, variables)
}