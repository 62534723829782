export const query = () => {
    return `{ merchant { apiKey, merchantUid, merchantName, interchange {achBasis, achFixed, cardBasis, cardFixed}, basis {achBasis, achFixed, cardBasis, cardFixed}, fixed {achBasis, achFixed, cardBasis, cardFixed} } }`
}

export const addAppleCredentials = creds => {
    return `mutation { addIOSCredentials(nativeCreds: { uid: "${creds.uid}", cfBundleIdentifier: "${creds["cf_bundle_identifier"]}", appleTeamId: "${creds["apple_team_id"]}", apiKey: "${creds.apiKey}"} ) { updatedIOS { credentials } } } `
}

export const addAndroidCredentials = creds => {
    return `mutation { addAndroidCredentials(nativeCreds: {uid: "${creds.uid}", apkPackageName: "${creds["apk_package_name"]}", apkDigestProd: "${creds["apk_digest_prod"]}", apkDigestDebug: "${creds["apk_digest_debug"]}", apiKey: "${creds.apiKey}"} ) { updatedAndroid { credentials } } }`
}

export const deleteAppleCredentials = creds => {
    return `mutation { deleteIOSCredentials(nativeCreds: {uid: "${creds.uid}", cfBundleIdentifier: "${creds["cf_bundle_identifier"]}", appleTeamId: "${creds["apple_team_id"]}", apiKey: "${creds.apiKey}"}) { updatedIOS { credentials } } }`
}

export const deleteAndroidCredentials = creds => {
    return `mutation { deleteAndroidCredentials(nativeCreds: { uid: "${creds.uid}", apkPackageName: "${creds["apk_package_name"]}", apkDigestProd: "${creds["apk_digest_prod"]}", apkDigestDebug: "${creds["apk_digest_debug"]}", apiKey: "${creds.apiKey}"}) { updatedAndroid { credentials } } }`
}

export const listPaymentParameters = "{ paymentParameters { PaymentParametersType, PaymentParametersName, expiresIn, deniedBeforeDate, deniedAfterDate, maximumAmount, minimumAmount, maximumOccurrence, recurrencePeriod, validationLinkURL, enabled } }"
