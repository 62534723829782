import {appSyncQuery, transactions} from "./index";
import * as gql from "../constants/GraphQL";

const listString = `query listTransactions($query: SqlQuery, $offset_id: String, $offset: String, $limit: Int, $direction: MoveDirection, $payment_method_query: [QueryPair], $payor_query: [QueryPair]) {
  transactions(query: $query, offset_id: $offset_id, offset: $offset, limit: $limit, direction: $direction) {
                    items {
                        merchant_uid
                        transaction_id
                        transaction_date
                        status
                        settlement_batch
                        payment_method(query_list: $payment_method_query) {
                          card_brand
                          last_four
                          payment_type
                          payor(query_list: $payor_query) {
                            full_name
                            email
                            phone
                          }
                        }
                        reference
                        account_code
                        transaction_type
                        dispute_status
                        net_amount
                        gross_amount
                        fees
                        currency
                        failure_reasons
                        refunded_amount
                      }
                      total_row_count
                    }
}`

export const list = (order, offset, limit, filter, direction) => {
    let sort = [{key: 'transaction_date', direction: order}]
    const queryObject = {
        query_list: filter.transactionQuery,
        sort_list: sort,
    }
    const variables = {
        query: queryObject,
        offset_id: offset?.transactionId,
        offset: offset?.transactionDate,
        limit: limit,
        direction: direction,
        payment_method_query: filter.paymentMethodQuery,
        payor_query: filter.payorQuery,
    }
    return appSyncQuery(listString, variables)
}

const refundString = `mutation CreateRefund($amount: Int!, $refund_email: String, $reason_code: RefundReasonCode!, $transaction_id: String!, $reason_details: String) {
              createRefund(amount: $amount, refund_email: $refund_email, transaction_id: $transaction_id, refund_reason: {reason_code: $reason_code, reason_details: $reason_details})
            }`

export const refund = (amount, reason, description, refundEmail, transactionId) => {
    let amountInt = parseInt(amount.replace(/[^0-9]/g, ''));
    let refundVariables = {
        amount: amountInt,
        reason_code: reason,
        reason_details: description,
        refund_email: refundEmail,
        transaction_id: transactionId
    }
    return appSyncQuery(refundString, refundVariables)
}

const getString = `query getTransactionAndRefunds($query: SqlQuery, $limit: Int = 100) {
                transactions(query: $query, limit: $limit) {
                  items {
                    merchant_uid
                    transaction_id
                    transaction_date
                    status
                    settlement_batch
                    payment_method {
                      card_brand
                      last_four
                      payment_type
                      payor {
                        full_name
                        email
                        phone
                      }
                    }
                    reference
                    account_code
                    transaction_type
                    dispute_status
                    net_amount
                    gross_amount
                    fees
                    currency
                    recurring {
                        recurring_id
                    }
                    invoice {
                        invoice_id
                    }
                    failure_reasons
                    refunded_amount
                    refund_reason {
                        reason_code
                        reason_details
                      }
                  }
              }
            }`

export const get = (transactionId) => {
    if (transactionId.includes(':')) {
        transactionId = transactionId.split(':')[0]
    }
    const query = {
        query_list: [
            {
                key: "transaction_id",
                value: `%${transactionId}%`,
                operator: gql.LIKE
            }
        ]
    }
    const variables = {
        query: query
    }
    return appSyncQuery(getString, variables)
}

export const getChartData = `query getChartData($query: SqlQuery, $limit: Int = 100, $offset_id: String, $offset: String) {
                transactions(query: $query, limit: $limit, offset_id: $offset_id, offset: $offset) {
                  items {
                    transaction_id
                    transaction_date
                    gross_amount
                    transaction_type
                  }
                  total_row_count
              }
            }`

export const getInvoicePayments = (invoiceId) => {

    const query = {
        query_list: [
            {
                key: "invoice_id",
                value: `%${invoiceId}%`,
                operator: gql.LIKE
            }
        ]
    }
    const variables = {
        query: query
    }
    return appSyncQuery(getString, variables)
}