import { convertArrayToGraphQLString } from "../util";

export const list = `{ disputes{ items { 
                            merchant, 
                            transactionId, 
                            disputeId, 
                            status, 
                            reason, 
                            amount, 
                            chargebackDate, 
                            updatedDate, 
                            expirationDate, 
                            accountCode, 
                            descriptor, 
                            lastFour, 
                            cardBrand, 
                            fullName, 
                            reasonMessage, 
                            settlementBatch, 
                            transactionDate, 
                            phone, 
                            email,
                            evidenceLastSendDate } 
                        }
                    }`;

export const uploadEvidence = (disputeId, fileNames, contentTypes) => {
    const fileNamesString = convertArrayToGraphQLString(fileNames);
    const contentTypesString = convertArrayToGraphQLString(contentTypes);
    return `{ 
        disputeEvidence(disputeId:"${disputeId}", action:"put", filenames:${fileNamesString}, contentTypes:${contentTypesString}) {
            documentPaths
         }}`
}

export const downloadEvidence = (disputeId, fileName) => {
    return `{ 
        disputeEvidence(disputeId:"${disputeId}", action:"get", filenames:["${fileName}"], contentTypes:["null"]) {
            documentPaths
         }}`
}

export const listEvidence = (disputeId) => {
    return `{ 
        disputeEvidenceRecords(disputeId:"${disputeId}") {
            items {
                filename,
                disputeId,
                evidenceId,
                evidenceDate,
                updatedDate,
                updatedRowAt
            }
         }}`
}

export const sendEvidence = (disputeId) => {
    return `mutation { 
        sendEvidence(sendEvidenceData: { disputeId:"${disputeId}" }) {
            sendEvidenceResponse {
                success
            }
         }}`
}

export const deleteEvidence = (disputeId, fileName) => {
    return `mutation { 
        deleteEvidence(deleteEvidenceData: { disputeId:"${disputeId}", filename:"${fileName}" }) {
            deleteEvidenceResponse {
                success
            }
         }}`
}
